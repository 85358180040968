import React, {createRef} from "react";
import {Container, MaxBtn} from "../core/components";

const width = 500, height = 300;
export default class Test extends React.Component {
    componentDidMount = async () => {
        /*navigator.mediaDevices.getUserMedia({video: true}).then(stream=>{
            console.log("stream",stream,this.ref.current)

            this.ref.current.srcObject=stream;
            //this.ref.current.src = URL.createObjectURL(stream);
        })*/
        /*const video = this.ref.current;
        const mediaStream = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
        video.srcObject = mediaStream;
        try {
            //video.src = URL.createObjectURL(mediaStream);
            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');
        } catch (e) {
            alert(e)
        }*/

        await navigator.mediaDevices.getDisplayMedia( {
            video: {
                cursor: "always"
            },
            audio: false
        }).then(stream=>{
            const screenVideo=this.ref2.current;
            screenVideo.srcObject=stream;
            screenVideo.setAttribute('autoplay', '');
            screenVideo.setAttribute('muted', '');
            screenVideo.setAttribute('playsinline', '');
        });

    }
    ref = createRef();
    ref2 = createRef();
    canvas = createRef();
    photo = createRef();
    test = () => {

    }
    takepicture = () => {
        const canvas = this.canvas.current
        const video = this.ref.current;
        const photo = this.photo.current;

        var context = canvas.getContext('2d');
        let photos = []
        if (width && height) {
            canvas.width = width;
            canvas.height = height;
        }
        const timer = window.setInterval(() => {

            context.drawImage(video, 0, 0, width, height);

            var data = canvas.toDataURL('image/png');
            //console.log(data)
            photos.push(data)
            //photo.setAttribute('src', data);
        }, 500)
        window.setTimeout(() => {
            clearInterval(timer)
            this.setState({photos})
        }, 1000)

    }
    state = {}

    render() {
        return <Container name={"test"}>
            <button onClick={this.takepicture}>test</button>
            <br/>
            <video ref={this.ref} width={width} height={height}/>
            <video ref={this.ref2} width={width} height={height}/>
            <br/>
            <canvas style={{display: "none"}} ref={this.canvas}/>
            {this.state.photos?.map(a => <img src={a}/>)}
        </Container>
    }
}
