import React, {useContext, useEffect, useMemo, useState} from "react";
import {MiniBtn, MyModal} from "../core/input_fields";
import {UserContext} from "../user/UserContext";
import {maxiGet, maxiPost} from "../core/maxios";
import {FaCheck, FaUser, FaUsers} from "react-icons/all";
import Consts from "../core/consts";
import Status from "../core/status";
import {InfoTooltip} from "../core/components";

function dateFormatTime(inp) {
    if (!!inp) {
        let date = new Date(inp);
        if (!!date) {
            return new Intl.DateTimeFormat('en-GB', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: "2-digit",
                weekday: "short"
            }).format(date < 1000000000000 ? date * 1000 : date);
        }
    }
    return "unknown"
}

function RecordingDetailModal({a, user, filesObject, filesName, loadFiles}) {
    const [error, setError] = useState("");
    const files = filesObject[a.Key.split(".webm")[0]] || [];

    return <MyModal trigger={getName(a.Key)}>

        <div style={{marginBottom: "10px"}}>
            <MiniBtn style={{float: "right"}} onClick={() => {
                if (window.confirm("Are you sure you want to delete this recording?")) {
                    maxiPost({
                        url: "/conferences/recordings/delete",
                        data: ({filePrefix: a.Key.endsWith(".webm") ? a.Key.replace(".webm", "") : a.Key}),
                        setError: e => setError(e.toString()),
                        success: loadFiles,
                    })
                }
            }}>Delete this recording?</MiniBtn>

            recording {getName(a.Key)} at {dateFormatTime(getTime(a.Key))} ({Math.round(a.Size / 1024 / 1024)}mb), &nbsp;
            <br/>
            <ul>
                {

                    files.filter(file => file.Key.endsWith("smooth.mp4") || file.Key.endsWith(".webm"))
                        .map(file => {
                            const mp4Version = file.Key.endsWith(".webm") ? files.find(a => a.Key === file.Key + ".mp4") : false;
                            return <li key={file.Key} style={{margin: "10px  0"}}>

                                {file.Key.indexOf("smooth") > -1 ? "AI smoothened version" : "normal version"} <br/>&nbsp;
                                <a
                                    target={"_blank"} rel={"noopener noreferrer"}
                                    href={file.Key.endsWith(".webm") ?
                                        (mp4Version ? `https://s3.linkom.at/${filesName}/${file.Key}.mp4` : `${Consts.f("http://localhost:5000", "https://my.recordee.io/api")}/re_conv/mp4/30/${file.Key}`) :
                                        `https://s3.linkom.at/${filesName}/${file.Key}`}
                                >
                                    (mp4 / h264)
                                </a>{mp4Version === undefined && <> (not yet fully available) <InfoTooltip>The fully compatible MP4 version is currently prepared. This version is generated on the fly and may be incompatible with older programs.</InfoTooltip></>}, &nbsp;&nbsp;&nbsp;
                                <a target={"_blank"} rel={"noopener noreferrer"} href={`${Consts.f("http://localhost:5000", "https://my.recordee.io/api")}/re_conv/mp3/${file.Key}`}>
                                    (audio only / mp3)
                                </a>, &nbsp;&nbsp;&nbsp;
                                <a target={"_blank"} rel={"noopener noreferrer"} href={`${Consts.f("http://localhost:5000", "https://my.recordee.io/api")}/re_conv/mxf/30/${file.Key}`}>
                                    (mxf / XDCAM, 30fps)
                                </a>, &nbsp;&nbsp;&nbsp;
                                <a target={"_blank"} rel={"noopener noreferrer"} href={`${Consts.f("http://localhost:5000", "https://my.recordee.io/api")}/re_conv/mxf/25/${file.Key}`}>
                                    (mxf / XDCAM, 25fps)
                                </a>, &nbsp;&nbsp;&nbsp;
                                <a target={"_blank"} rel={"noopener noreferrer"} href={`${Consts.f("http://localhost:5000", "https://my.recordee.io/api")}/re_conv/mov/25/${file.Key}`}>
                                    (mov / ProRes, 30fps)
                                </a>

                                {
                                    (user?.company_ID === 2 || user.ID === 1) && <UploadToFTP file={`${Consts.f("http://localhost:5000", "https://my.recordee.io/api")}/re_conv/mxf_to_ftp/25/${file.Key}`}/>
                                }

                                {
                                    !user.payingTill && file.Key.indexOf("smooth") > -1 && file.Size > 1024 * 1024 * 15 && <><br/><br/>The AI frame rate enhancement is limited to 60 seconds per clip in the free version!</>
                                }
                            </li>
                        })
                }
            </ul>

        </div>
        <Status type={"error"} text={error}/>
        <video height={700} controls src={`https://s3.linkom.at/${filesName}/${a.Key}${a.Key.endsWith("app.webm") ? ".mp4" : ""}`}/>
    </MyModal>
}

export default function ShowRecordings({roomName, userLoggedIn, setRoomName, loadFiles, files}) {
    useEffect(() => {
        loadFiles()
        const timer = window.setInterval(loadFiles, 5000);
        return () => {
            window.clearInterval(timer);
        };
    }, [roomName, userLoggedIn]);


    const user = (useContext(UserContext) || {}).user || {};
    const filesObject = files && files.Contents?.reduce((obj, curr) => ({...obj, [curr.Key.split(".webm")[0]]: [...(obj[curr.Key.split(".webm")[0]] || []), curr]}), {})

    return useMemo(() => <>
        {
            roomName && <>

                <h1>recordings</h1>
                {
                    files && <table className={"recordingsTable"}>
                        <tbody>
                        <tr>
                            <th>participant</th>
                            <th>time</th>
                            <th>size</th>

                        </tr>
                        {
                            files.Contents?.filter(a => a.Key.endsWith(".webm") || a.Key.endsWith("room.webm.mp4")).map(a => <tr key={a.Key}>

                                <td>
                                    <RecordingDetailModal {...{a, filesName: files.Name, filesObject, user, loadFiles}}/>
                                </td>
                                <td>
                                    {dateFormatTime(getTime(a.Key))}
                                </td>
                                <td>
                                    {Math.round(a.Size / 1024 / 1024) + "mb"}
                                </td>
                            </tr>)
                        }
                        </tbody>
                    </table>
                }
                {/*
                    files && files.Contents?.filter(a => a.Key.endsWith(".webm") || a.Key.endsWith("room.webm.mp4")).map(a => <MyModal
                        trigger={<li><em>{a.Key.indexOf(".mp4") === -1}recording of {getName(a.Key)} at {dateFormatTime(new Date(a.LastModified))} ({Math.round(a.Size / 1024 / 1024)}mb)</em></li>}>

                        <div style={{marginBottom: "10px"}}>
                            recording {getName(a.Key)} at {dateFormatTime(new Date(a.LastModified))} ({Math.round(a.Size / 1024 / 1024)}mb),&nbsp;
                            <br/>
                            {
                                (filesObject[a.Key.split(".webm")[0]] || [])
                                    .filter(file => file.Key.endsWith(".mp4"))
                                    .map(file => <li>
                                        download&nbsp;
                                        {file.Key.indexOf("smooth") > -1 && "the AI smoothened "} &nbsp;
                                        <a target={"_blank"} rel={"noopener noreferrer"} href={`https://s3.linkom.at/${files.Name}/${file.Key}`}>
                                            (mp4 / h264)
                                        </a>, &nbsp;&nbsp;&nbsp;
                                        <a target={"_blank"} rel={"noopener noreferrer"} href={`https://my.recordee.io/api/re_conv/mxf/30/${file.Key}`}>
                                            (mxf / XDCAM 422, 30fps)
                                        </a>, &nbsp;&nbsp;&nbsp;
                                        <a target={"_blank"} rel={"noopener noreferrer"} href={`https://my.recordee.io/api/re_conv/mxf/25/${file.Key}`}>
                                            (mxf / XDCAM 422, 25fps)
                                        </a>
                                        {
                                            (user?.verein_ID === 7 || user.ID === 1376) && <UploadToFTP file={`https://my.recordee.io/api/re_conv/mxf_to_ftp/25/${file.Key}`}/>
                                        }
                                    </li>)
                            }
                        </div>
                        <video height={700} controls src={`https://s3.linkom.at/${files.Name}/${a.Key}`}/>
                    </MyModal>)
*/
                }
                {
                    !files?.Contents?.length > 0 && "No recordings found!"
                }
            </>
        }
        {
            userLoggedIn
            &&
            typeof files?.Folders === "object" && !roomName && <>
                <br/>
                <h1>previous video conferences</h1>
                <table className={"recordingsTable"}>
                    <tbody>
                    <tr>
                        <th>
                            link
                        </th>
                        <th>
                            participants
                        </th>
                        <th>
                            time
                        </th>
                    </tr>
                    {
                        Object
                            .entries(files?.Folders)
                            .sort(([, a], [, b]) => -new Date(a.last).getTime() + new Date(b.last).getTime())
                            .map(([key, {names, last}]) => <tr key={key}>
                                    <td><a onClick={() => setRoomName(key)}>my.recordee.io/{key}</a></td>
                                    <td>{names.filter(a => a !== "room").sort().join(", ")}</td>
                                    <td>{dateFormatTime(last)}</td>
                                </tr>
                            )
                    }
                    </tbody>
                </table>
            </>


        }
    </>, [files, files?.Contents?.length, files?.Folders?.length])
}

function UploadToFTP({file}) {
    const [uploadStarted, setUploadStarted] = useState(false);
    const upload = () => maxiGet({
        url: file,
        noApi: true,
        success: resp => {
            setUploadStarted(true);
        }
    })
    return <>, &nbsp;&nbsp;&nbsp;
        (<a onClick={upload}>
            upload mxf to ftp.ORF.at {uploadStarted && <FaCheck/>}
        </a>)
    </>
}

function getName(key) {
    const s = key.split("-");
    if (s.length < 2) {
        return <em>anonymous</em>;
    }
    const s2 = s[s.length - 1].split(".")
    if (s2[0] === "room") {
        return <><FaUsers/> &nbsp; <em> all participants</em></>
    }
    return <><FaUser/> &nbsp; <em>{s2[0]}</em></>;
}


function getTime(key) {
    const s = key.split("-");
    if (s.length < 2) {
        return undefined
    }
    const t = s[1].split("/");
    if (t.length < 2) {
        return undefined
    }
    return t[1] * 1000
}

