import React from "react";
import {isIOS13, isIPad13, isMobileSafari} from "react-device-detect";
//import JitsiMeetExternalAPI from "lib-jitsi-meet-dist/dist/external_api.min";
import {MaxBtn} from "../core/components";
import {RecordeeContext} from "./context";
import {FaExpandArrowsAlt, FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash} from "react-icons/all";


const domain = 'meet.vereinfacht.com';

export default class Jitsi extends React.Component {
    constructor(props) {
        super(props);
        this.videoContainer = React.createRef()
    }

    state = {};
    setState = this.setState.bind(this);
    static contextType = RecordeeContext;
    componentDidMount = () => {

        if (isMobileSafari || isIOS13 || isIPad13) {
            return;
        }


        //const domain = 'jitsi.do.linkom.at';
        const options = {
            roomName: this.context.roomName.toLowerCase(),
            width: window.innerWidth,
            height: window.innerHeight * .5,
            parentNode: this.videoContainer.current,
            interfaceConfigOverwrite: {
                TOOLBAR_BUTTONS: ["tileview", "fullscreen", "desktop", "chat"].concat((this.context.role > 20 || this.props.isPowerUser) ? ['mute-everyone'] : []),
                LANG_DETECTION: false,
                SHOW_BRAND_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                OPEN_IN_MOBILE_BROWSER: true,
                UNSUPPORTED_BROWSERS: ["safari", "firefox"],
                VIDEO_QUALITY_LABEL_DISABLED: true,
                RECENT_LIST_ENABLED: false,
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                SHOW_JITSI_WATERMARK: false,
                DISABLE_RINGING: true,
                JITSI_WATERMARK_LINK: "https://vereinfacht.at",
                APP_NAME: "VEREINfacht Live",
                PROVIDER_NAME: "VEREINfacht",
                DISABLE_PRESENCE_STATUS: true,
                HIDE_KICK_BUTTON_FOR_GUESTS: true,
                BRAND_WATERMARK_LINK: "https://vereinfacht.at",
            },
            configOverwrite: {
                disableRecordAudioNotification: true,
                defaultLanguage: "en",
                disableRemoteMute: !(this.context.role > 20 || this.props.isPowerUser),
            },
            userInfo: {
                name: this.context.userName,
            },
            devices: {
                audioInput: this.context.state?.audioDeviceId,
                videoInput: this.context.state?.videoDeviceId,
            }
        };
        if (!window.JitsiMeetExternalAPI) {
            //alert("Please check your internet connection! The Jitsi lib was not loaded!")
            //window.location.reload()
            throw new Error("Please check your internet connection (lib loading error)!")
            return
        }
        const api = new window.JitsiMeetExternalAPI(domain, options);
        //api.isAudioMuted().then(muted => !muted && api.executeCommand('toggleAudio'));
        if (!!this.context.userName) {
            api.executeCommand('displayName', this.context.userName);
        }
        api.executeCommand('subject', ' ');
        this.api = api;
        //api.addEventListener("readyToClose", () => maxiPost({url: "/conferences/hangup", data: {roomName: context.roomName}, success: this.props.reload}));
        //api.executeCommand("toggleTileView")
        //api.executeCommand('toggleAudio');

        window.setInterval(() => {

            api.getCurrentDevices().then(async ({videoInput, audioInput, ...rest}) => {
                if (videoInput === undefined) {
                    return
                }

                const devices = await api.getAvailableDevices()

                if (this.context.state?.videoDeviceIdLabel !== videoInput.label) {
                    api.setVideoInputDevice(devices.videoInput.find(({label}) => label === this.context.state?.videoDeviceIdLabel)?.label, this.context.state?.videoDeviceId)
                }
                if (this.context.state?.audioDeviceIdLabel !== audioInput.label) {
                    api.setAudioInputDevice(devices.audioInput.find(({label}) => label === this.context.state?.audioDeviceIdLabel)?.label, this.context.state?.audioDeviceId)
                }
            });

        }, 1000)
        api.addEventListener("audioMuteStatusChanged", ({muted}) => {
            this.setState({muted})
        })

        api.addEventListener("videoMuteStatusChanged", ({muted}) => {
            this.setState({videoMuted: muted})
        })

    };


    render() {
        const context = (this.context || {});

        const {muted, videoMuted} = this.state;
        return <div id={"JitsiMeetContainer"}>
            <div style={{marginLeft: "10px", marginTop: "20px", position: "fixed", zIndex: 0}}>
                <MaxBtn style={{marginRight: 0}} onClick={() => {
                    const newHeight = parseFloat(document.getElementById("jitsiConferenceFrame0").style.height) < window.innerHeight * 0.6 ? (window.innerHeight - 150) : window.innerHeight * 0.5
                    document.getElementById("jitsiConferenceFrame0").style.height = newHeight + "px"
                    document.getElementById("JitsiMeetContainer").style.height = newHeight + "px"
                }}><FaExpandArrowsAlt/></MaxBtn>&nbsp;&nbsp;
                <MaxBtn onClick={() => {
                    this.api.executeCommand("toggleAudio")
                }}>{muted ? <><FaMicrophoneSlash/> Unmute</> : <><FaMicrophone/> Mute</>}</MaxBtn>
                <MaxBtn style={{marginRight: 0}} onClick={() => {
                    this.api.executeCommand("toggleVideo")
                }}>{videoMuted ? <><FaVideoSlash/> Turn Camera Transmission On</> : <><FaVideo/> Turn Camera Transmission Off</>}</MaxBtn>

            </div>
            {
                /*isMobileSafari || isIOS13 || isIPad13*/false ? <>
                    <MaxBtn className={"btn"}
                            onClick={() => window.location.href = "org.jitsi.meet://" + domain + "/" + ((context || {}).roomName || "").toLowerCase() + "#jitsi_meet_external_api_id=0&config.disableRecordAudioNotification=true&config.defaultLanguage=%22de%22&config.startWithAudioMuted=true&config.startWithVideoMuted=true&config.startAudioMuted=0&config.startVideoMuted=0&config.disableRemoteMute=false&interfaceConfig.TOOLBAR_BUTTONS=%5B%22camera%22%2C%22microphone%22%2C%22tileview%22%2C%22fullscreen%22%2C%22desktop%22%2C%22hangup%22%2C%22mute-everyone%22%5D&interfaceConfig.LANG_DETECTION=false&interfaceConfig.SHOW_BRAND_WATERMARK=false&interfaceConfig.SHOW_WATERMARK_FOR_GUESTS=false&interfaceConfig.OPEN_IN_MOBILE_BROWSER=true&interfaceConfig.UNSUPPORTED_BROWSERS=%5B%5D&interfaceConfig.VIDEO_QUALITY_LABEL_DISABLED=true&interfaceConfig.RECENT_LIST_ENABLED=false&interfaceConfig.DISABLE_JOIN_LEAVE_NOTIFICATIONS=true&interfaceConfig.SHOW_JITSI_WATERMARK=false&interfaceConfig.DISABLE_RINGING=true&interfaceConfig.JITSI_WATERMARK_LINK=%22https%3A%2F%2Fvereinfacht.at%22&interfaceConfig.APP_NAME=%22VEREINfacht%20Live%22&interfaceConfig.PROVIDER_NAME=%22VEREINfacht%22&interfaceConfig.DISABLE_PRESENCE_STATUS=true&interfaceConfig.HIDE_KICK_BUTTON_FOR_GUESTS=true&interfaceConfig.BRAND_WATERMARK_LINK=%22https%3A%2F%2Fvereinfacht.at%22"}>
                        In der App öffnen
                    </MaxBtn>
                    <br/>
                    <MaxBtn
                        onClick={() => window.location.href = "https://w2atb.app.goo.gl/?link=https%3A%2F%2F" + domain + "%2F" + ((context || {}).roomName || "").toLowerCase() + "%25200%23jitsi_meet_external_api_id%3D0%26config.disableRecordAudioNotification%3Dtrue%26config.defaultLanguage%3D%2522de%2522%26config.startWithAudioMuted%3Dtrue%26config.startWithVideoMuted%3Dtrue%26config.startAudioMuted%3D0%26config.startVideoMuted%3D0%26config.disableRemoteMute%3Dfalse%26interfaceConfig.TOOLBAR_BUTTONS%3D%255B%2522camera%2522%252C%2522microphone%2522%252C%2522tileview%2522%252C%2522fullscreen%2522%252C%2522desktop%2522%252C%2522hangup%2522%252C%2522mute-everyone%2522%255D%26interfaceConfig.LANG_DETECTION%3Dfalse%26interfaceConfig.SHOW_BRAND_WATERMARK%3Dfalse%26interfaceConfig.SHOW_WATERMARK_FOR_GUESTS%3Dfalse%26interfaceConfig.OPEN_IN_MOBILE_BROWSER%3Dtrue%26interfaceConfig.UNSUPPORTED_BROWSERS%3D%255B%255D%26interfaceConfig.VIDEO_QUALITY_LABEL_DISABLED%3Dtrue%26interfaceConfig.RECENT_LIST_ENABLED%3Dfalse%26interfaceConfig.DISABLE_JOIN_LEAVE_NOTIFICATIONS%3Dtrue%26interfaceConfig.SHOW_JITSI_WATERMARK%3Dfalse%26interfaceConfig.DISABLE_RINGING%3Dtrue%26interfaceConfig.JITSI_WATERMARK_LINK%3D%2522https%253A%252F%252Fvereinfacht.at%2522%26interfaceConfig.APP_NAME%3D%2522VEREINfacht%2520Live%2522%26interfaceConfig.PROVIDER_NAME%3D%2522VEREINfacht%2522%26interfaceConfig.DISABLE_PRESENCE_STATUS%3Dtrue%26interfaceConfig.HIDE_KICK_BUTTON_FOR_GUESTS%3Dtrue%26interfaceConfig.BRAND_WATERMARK_LINK%3D%2522https%253A%252F%252Fvereinfacht.at%2522&apn=org.jitsi.meet&ibi=com.atlassian.JitsiMeet.ios&isi=1165103905&ius=org.jitsi.meet&efr=1"}>
                        App herunterladen
                    </MaxBtn>

                </> : <div ref={this.videoContainer} id={"JitsiMeet"}>
                    <div id={"startingMessage"}>Starting Video Conference ...</div>

                </div>
            }
        </div>
    }
}
