import React from "react";
import {RecordeeContext} from "./context";
import {MaxBtn} from "../core/components";
import Status from "../core/status";
import {apiGet, apiPost} from "../core/api";
import Consts from "../core/consts";
import ReactDOM from "react-dom";
import {createMultipartUpload, getAndUpload, getFileSystem} from "./multipartupload";
import {create as timeSyncCreate} from "timesync";
import {isMobileSafari} from "react-device-detect";

const constraintsGlobalVideo = {
    width: {
        ideal: 1920, max: 1080,
    },
    height: {
        ideal: 1920, max: 1080,
    },

    frameRate: Consts.f({
        ideal: 30, max: 30, min: 8
    }, {
        ideal: 30, max: 30, min: 8
    }),
    advanced: [
        {width: 1920, height: 1080},
        {aspectRatio: 1.777}
    ],
};

const constraintsGlobalAudio = {
    /*noiseSuppression: true,
    echoCancellation: true,
    googEchoCancellation: true,
    googNoiseSuppression: true,*/
};

/*navigator.getUserMedia = navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia;*/

function getConnectionData() {
    return {
        downlink: navigator.connection?.downlink,
        effectiveType: navigator.connection?.effectiveType,
        rtt: navigator.connection?.rtt,

    }
}

async function getWebCamSpecs(settings) {
    if (!navigator.getBattery) {
        return {
            resolution: {
                width: settings.width,
                height: settings.height,
            },
            frameRate: settings.frameRate,
        }
    }
    const battery = await navigator.getBattery()
    //console.log("recordee on battery", battery.dischargingTime)
    return {
        resolution: {
            width: settings.width,
            height: settings.height,
        },
        frameRate: settings.frameRate,
        connection: getConnectionData(),
        battery: battery.dischargingTime
    }
}

export default class Socket extends React.Component {

    constructor(props) {
        super(props);
        localStorage.getItem("token") || localStorage.setItem("token", Math.floor(Math.random() * 100000000))
        console.log("token", localStorage.getItem("token"))
        this.state = {
            started: false,
            mediaRecorder: null,
            progress: null,
            startTime: null,
            reloadTime: undefined,
            webcamSpecs: null,
            token: localStorage.getItem("token"),
        };
    }

    apiPost = apiPost.bind(this);
    apiGet = apiGet.bind(this);
    setState = this.setState.bind(this);
    static contextType = RecordeeContext;
    findPerfectResolution = async (streamIn = null) => {
        if (this.state.startTime > 0) {
            return
        }
        const stream = streamIn || this.state.stream;

        for (var i = 0; i < Consts.ResolutionsToCheck.length; i++) {
            const res = Consts.ResolutionsToCheck[i]
            try {

                await stream.getVideoTracks()[0].applyConstraints({
                    width: {exact: res.width},
                    height: {exact: res.height},
                    frameRate: 30,
                })
                const {resizeMode, frameRate, width, height} = stream.getVideoTracks()[0].getSettings()
                console.log("recordee", resizeMode)
                if (frameRate > 7 && width === res.width && height === res.height) {
                    break;
                } else {
                    console.log(`recordee resolution ${res.width}x${res.height} could not be established because it would have ${frameRate} fps then`)
                }
            } catch (e) {
                //console.log("recordee error", e)
            }
        }
        const settings = stream.getVideoTracks()[0]?.getSettings() || {};
        if (settings.width !== this.state.webcamSpecs?.resolution?.width || settings.frameRate !== this.state.webcamSpecs?.frameRate) {
            //console.log("recordee", (navigator.connection))
            this.setState({webcamSpecs: await getWebCamSpecs(settings)})
        }
    }

    startStream = async constraints => {
        return navigator.mediaDevices.getUserMedia(constraints).then(async stream => {
            console.log("recordee stream started", constraints.audio, stream.getTracks())

            //console.log("recordee stream started with mic", stream.getAudioTracks()[0].label)
            await this.findPerfectResolution(stream)
            if (!this.findPerfectResolutionTimer) {
                this.findPerfectResolutionTimer = window.setInterval(() => {
                    this.findPerfectResolution()
                    //console.log("api", stream.getVideoTracks()[0].getSettings())
                }, 2000)
            }
            const settings = stream.getVideoTracks()[0]?.getSettings() || {};

            await this.setState({
                stream,
                audioDeviceId: this.context.state?.audioDeviceId,
                audioDeviceIdLabel: this.context.state?.audioDeviceIdLabel,
                videoDeviceId: this.context.state?.videoDeviceId,
                videoDeviceIdLabel: this.context.state?.videoDeviceIdLabel,
                recordingType: this.context.state?.recordingType,
                webcamSpecs: await getWebCamSpecs(settings),
            })

            this.context.setState({...this.context.state, streamStarted: true})
        }).catch(e => {
            alert(e.toString())
        });
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.state.stream !== undefined && (
            this.context.state?.recordingType !== this.state.recordingType ||
            this.context.state?.audioDeviceId !== this.state.audioDeviceId ||
            this.context.state?.audioDeviceIdLabel !== this.state.audioDeviceIdLabel ||
            this.context.state?.videoDeviceIdLabel !== this.state.videoDeviceIdLabel ||
            this.context.state?.videoDeviceId !== this.state.videoDeviceId //||
            // (!!this.state.settings?.maxResolution && this.state?.webcamSpecs?.resolution?.width > parseInt(this.state.settings?.maxResolution))
        )
        ) {
            //console.log("restart stream")
            this.startStream(
                {
                    video: {...constraintsGlobalVideo, deviceId: {exact: this.context.state?.videoDeviceId}},
                    audio: {...constraintsGlobalAudio, deviceId: {exact: this.context.state?.audioDeviceId}},
                }
            );

        }
    }

    setProgress = fileName => (total, offset) => progressEvent => this.setState({
        [fileName + "progress"]: progressEvent ? {
            progress: Math.round((offset + progressEvent.loaded) / total * 100),
            progressTotal: Math.round(total / 1024 / 1024, 1),
            progressLoaded: Math.round((offset + progressEvent.loaded) / 1024 / 1024, 1),
        } : null
    })
    componentDidMount = async () => {

        window.addEventListener('beforeunload', e => {
            if (Object
                .entries(this.state)
                .filter(([key, obj]) => key.indexOf("progress") > 5 && obj !== null).length > 0 || this.state.startTime > 0) {
                e.preventDefault();
                e.returnValue = '';
                return;
            }

            delete e['returnValue'];
        });
        getFileSystem(() => {
            console.log("got access to the file system")
        })

        this.ts = timeSyncCreate({
            server: Consts.f("", "/api") + '/timesync',
            interval: 60000
        });
        this.ts.on('change', (offset) => {
            console.log('changed offset: ' + offset + ' ms<br>', this.ts.now());
        });

        const roomInitial = this.context.roomName;
        if (roomInitial === null) {
            return
        }
        const filesToUpload = JSON.parse(localStorage.getItem("uploadStore") || "{}");
        Object.values(filesToUpload).forEach(file => {
            getAndUpload(file, this.setProgress(file.fileName), error => this.setState({error}),)
        })

        await this.startStream(
            {
                video: {...constraintsGlobalVideo, deviceId: {exact: this.context.state?.videoDeviceId}},
                audio: {...constraintsGlobalAudio, deviceId: {exact: this.context.state?.audioDeviceId}},
            }
        );
        // await necessary here because start stream can take some time to evaluate best framerate / resolution combo and start recording thus could otherwise probably be executed before

        const checkResponse = room => async resp => {
            if (!resp.room) {
                return
            }
            //console.log("recordee",this.context, navigator.mediaDevices.getSupportedConstraints())
            const reloadTime = this.state.reloadTime;
            const startTime = this.state.startTime;
            if (reloadTime !== resp.room?.reloadTime) {
                this.setState({reloadTime: resp.room?.reloadTime})
                this.props.reload()
            }
            this.setState({settings: resp.settings})

            this.context.setOtherParticipants(resp.participants)
            if (resp.room.startTime !== startTime) {
                this.setState({startTime: resp.room.startTime})
                this.context.setState({...this.context.state, startTime: resp.room.startTime});

                if (!startTime && resp.room.startTime > 0) {
                    this.setState({started: true})
                    const prettyName = this.context.userName
                        .replace("ä", "ae")
                        .replace("ö", "oe")
                        .replace("ü", "ue")
                        .split("")
                        .filter(char => "abcdefghijklmnopqrstuvwxyz ".indexOf(char.toLowerCase()) > -1)
                        .join("")

                    const stream = new MediaStream();
                    this.state.stream.getAudioTracks().forEach((track, i) => {
                        stream.addTrack(track)
                    })
                    if (resp.room.recordingType !== "audio") {
                        this.state.stream.getVideoTracks().forEach((track, i) => {
                            stream.addTrack(track)
                        })
                    }
                    console.log("recordee with bitrate", resp.settings?.maxBitrate)
                    let mediaRecorder = new window.MediaRecorder(stream, {
                        mimeType: isMobileSafari ? "video/mp4" : "video/webm;codecs=vp9",
                        videoBitsPerSecond: resp.settings?.maxBitrate === "unlimited" || !resp.settings?.maxBitrate ? undefined : parseInt(resp.settings?.maxBitrate) * 1000
                    });
                    const mediaStreamTrack = this.state.stream.getVideoTracks()[0];


                    const settings = (mediaStreamTrack && mediaStreamTrack.getSettings()) || {};
                    //console.log("recordee settings before record", settings)
                    this.setState({
                        mediaRecorder,
                        webcamSpecs: {
                            resolution: {
                                width: settings.width,
                                height: settings.height,
                            },
                            frameRate: settings.frameRate,

                        }
                    })
                    let chunks = [];
                    mediaRecorder.ondataavailable = (e) => {
                        chunks.push(e.data);
                    }
                    //console.log("recordee",(this.ts.now() / 1000) , resp.room.startTime);
                    const fileName = '/' + parseInt(resp.room.startTime) + "." + Math.round(((this.ts.now() / 1000) - resp.room.startTime) * 1000) + "-" + prettyName + ".webm"
                    mediaRecorder.onstop = e => {

                        const videoBlob = new Blob(chunks, {type: "video/webm;codecs=vp9"});

                        const exist = JSON.parse(localStorage.getItem("uploadStore")) || {}
                        localStorage.setItem("uploadStore", JSON.stringify({...exist, [fileName]: {...(exist[fileName] || {}), fileName, room, credentials: resp.room.credentials},}))
                        createMultipartUpload(
                            fileName,
                            room,
                            resp.room.credentials,
                            videoBlob,
                            this.setProgress(fileName),
                            null,
                            error => this.setState({error}),
                        )
                        getFileSystem(fs => {
                            fs.root.getFile(fileName, {create: true}, fileEntry => {
                                fileEntry.createWriter(fw => {
                                    fw.write(videoBlob)
                                })
                            }, e => {
                                console.log("recordee file system error ", e)
                            })
                        })


                    }
                    mediaRecorder.start()

                } else if (startTime > 0 && !resp.room.startTime) {
                    this.state.mediaRecorder && this.state.mediaRecorder.stop();
                    this.setState({started: false, mediaRecorder: null});
                }
                console.log(startTime, startTime > 0, resp.room.startTime)
            }
        };

        this.timer = window.setInterval(() => {
            const room = this.context.roomName;
            this.apiPost(
                "/recordee/" + room + "/get_status",
                {
                    token: this.state.token,
                    specs: this.state.webcamSpecs,
                    webcamName: this.context?.state?.videoDeviceIdLabel,
                    micName: this.context?.state?.audioDeviceIdLabel,
                    name: this.context.userName,
                    progressList: Object
                        .entries(this.state)
                        .filter(([key, obj]) => key.indexOf("progress") > 5 && obj !== null)
                        .map(([key, p]) => p)
                },
                checkResponse(room),
                true)
        }, 1000)
    }
    componentWillUnmount = () => {
        clearInterval(this.timer)
    }

    render() {
        const {started, uploadFinished, recordingType, error, ...stateRest} = this.state;
        const {roomName, userName, userID} = this.context;
        const {userLoggedIn} = this.props;
        //console.log("progres", Object.entries(stateRest).filter(([key, obj]) => key.indexOf("progress") > 5))
        return <>

            {
                userLoggedIn && roomName && parseInt(roomName.split("-")[0]) === userID &&
                <MaxBtn onClick={() => {
                    if (userName === null) {
                        alert("You have to type in a username first.")
                        window.location.reload()
                    }
                    this.apiPost("/recordee/" + roomName + "/status", {"action": started ? "stop" : "start", recordingType})
                }}>
                    {started ? "Stop" : "Start"} Recording
                </MaxBtn>
            }
            <PortalRenderer container={this.props.progressBarContainer}>
                <Status type={"error"} text={error !== "could not fetch from redis" ? error : ""}/>
                {
                    Object
                        .entries(stateRest)
                        .filter(([key, obj]) => key.indexOf("progress") > 5 && obj !== null)
                        .map(([key, {progress, progressLoaded, progressTotal}]) =>
                            <Status hideTitle type={"error"} text={`uploading ${progress} (${progressLoaded} / ${progressTotal} mb) percent. Do not close the window!`}/>
                        )
                }
            </PortalRenderer>
        </>
    }
}

function PortalRenderer({children}) {
    const container = document.getElementById("progressBarContainer")
    if (!container) {
        return null
    }
    return ReactDOM.createPortal(
        children,
        container
    )
}
