import React, {Component, useEffect,} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {Switch} from "react-router";
import {Container} from "./core/input_fields";
// ie stuff
import "babel-polyfill";
import {polyfill} from 'es6-promise';
import cssVars from 'css-vars-ponyfill';
import {Club, clubMap, domainMap} from "./core/custom";
import {UserContext} from "./user/UserContext";
import CheckLogin from "./user/check_login";
import ErrorBoundary from "./core/error_boundary";
import Recordee from "./recordee/recordee";
import "./general.sass"
import ChangePW from "./user/changepw";
import VideoEditor from "./recordee/editor";
import {FAQ} from "./recordee/faq";
import Test from "./recordee/test";
import {TestSafari} from "./recordee/test_safari";

polyfill();

// end ie stuff
function ScrollToTop({location}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

class App extends Component {
  state = {
    navOpen: true,//localStorage.getItem("mainContainer") !== "false",
    navToggle: () => {
      localStorage.setItem("mainContainer", !this.state.navOpen);
      this.setState({navOpen: !this.state.navOpen})
    },
    mounted: false,
    club: (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club)
  };

  addUserFromStatus = resp => {
    !!resp.club && this.setCssVars(resp.club);
    this.setState(resp);
  };

  setCssVars = club => cssVars({
    rootElement: document,
    variables: {
      "mainColor": club.color,
      "fontColor": club.fontColor || "#444",
      "fontColorInverse": club.fontColorInverse || "#fff",
    },
  });

  componentWillMount() {
    const club = (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club);
    this.setCssVars(club)
  }
b
  render() {

    return (
        <Router>

          <UserContext.Provider value={this.state}>
            <div style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              fontSize: "10px",
              backgroundColor: "white",
              padding: "5px",
              zIndex: 1000,
            }}>
              version {window.vatomatorVersion}
            </div>
            <div className={"App "}>
              <Route path="/" render={(props) => <CheckLogin {...props} addUserFromStatus={this.addUserFromStatus}/>}/>

              <Route path="/" component={ScrollToTop}/>
              <MainContainer>
                <ErrorBoundary key={"ljljfs"}>
                  <Switch>

                    <Route exact path={"/"} component={Recordee}/>
                    <Route exact path={"/faq"} component={FAQ}/>
                    <Route exact path={"/test"} component={Test}/>
                    <Route exact path={"/ve"} component={VideoEditor}/>
                    <Route exact path={"/password/:token"} component={ChangePW}/>
                    <Route exact path={"/testSafari"} component={TestSafari}/>
                    <Route exact path={"/:roomName"} component={Recordee}/>

                    <Route path={"/"} component={Notfound}/>
                  </Switch>
                </ErrorBoundary>
              </MainContainer>

            </div>
          </UserContext.Provider>
        </Router>
    );
  }
}


class MainContainer extends React.Component {
  state = {
    mounted: false,
  };
  componentDidMount = () => {
    window.setTimeout(() => {
      this.setState({mounted: true})
    }, 2000);
  };
  static contextType = UserContext;

  render() {
    const userContext = this.context;
    return <article style={{zIndex: 10}} id={"mainContainer"}
                    className={
                      (userContext.navOpen ?
                              "open" :
                              "closed"
                      ) +
                      (this.state.mounted ? " postLoad" : "")}
                    onClick={() => userContext.navOpen && !!userContext.user && userContext.user.status === "logged_in" && window.innerWidth < 500 && userContext.navToggle()}
    >
      {this.props.children}
    </article>
  }
}
function Notfound(props) {
  if (props.location.pathname.indexOf("/benutzer") > -1) {
    return null
  }
  return <Container name={"Error"}>This page will be added soon.</Container>
}

export default App;
