import React, {useMemo, useState} from "react";

export function Counter({start}) {

    const [delta, setDelta] = useState(null);
    useMemo(() => {
        const calcDelta = () => new Date().getTime() / 1000 - start
        window.setInterval(() => {
            setDelta(calcDelta())
        }, 1000)
    }, [])
    return <>
        {Math.floor(delta / 60)}:{Math.floor(delta % 60).pad(2)}
    </>
}
