import Jitsi from "./Jitsi";
import React, {useMemo, useRef, useState} from "react";
import {RecordeeContext} from "./context";

export function TestSafari({}) {
    const ref=useRef();
    const ref2=useRef();
    const [err1, setErr1]=useState();
    const [err2, setErr2]=useState();
    useMemo(()=>{
        window.setTimeout(()=> {
            navigator.mediaDevices.getUserMedia({video: {width: 100, height: 100}, audio: false}).then(stream => {
                ref.current.srcObject = stream;
                ref.current.setAttribute('autoplay', '');
                ref.current.setAttribute('muted', true);
                ref.current.setAttribute('playsinline', '');
            }).catch(a=>setErr1(a.toString()))
            navigator.mediaDevices.getUserMedia({video: {width: 200, height: 100}, audio: false}).then(stream => {
                ref2.current.srcObject = stream;
                ref2.current.setAttribute('autoplay', '');
                ref2.current.setAttribute('muted', true);
                ref2.current.setAttribute('playsinline', '');
            }).catch(a=>setErr2(a.toString()))
        },1000)
    }, [])
    return <>
        {/*<RecordeeContext.Provider value={{roomName: "test123"}}>

            <Jitsi isPowerUser={true} roomName={"test123"}/>
        </RecordeeContext.Provider>*/}
        test
        error1: {err1}
        error2: {err2}
        <video ref={ref}/>
        <video ref={ref2}/>
        </>
}
