import React, {useContext} from "react"
import Consts from "./consts";
import {UserContext} from "../user/UserContext";

type _Name = {
    verein_ID: number,
    fullName: string,
    fullNameDeclined: string,
    shortName: string,
    domain: string,
    title: string,
    logo?: JSX.Element,
    color: string,
    fontColor?: string,
    fontColorInverse?: string,
    showLogo: boolean,
    iban: string,
    facebook?: string,
    memberStates: Record<number, string>,
    memberStatesShort?: Record<number, string>,
    arnName: string,
    arn: string,
    sendReminders: boolean,
    address: string,
    creditorID: string,
    email?: string,
    phone?: number,
    links?: {
        dataprotection?: string
        statuten?: string
    }
}

type _domainMap = Record<string, _Name>
const memberStatesStandard = {
    0: "Nichtmitglied",
    1: "ordentliches Mitglied",
    2: "außerordentliches Mitglied",
    3: "unterstützendes Mitglied",

    400: "befreites Mitglied",
    401: "ruhendes Mitglied",
    402: "Trainer",

    500: "Zeugwart Stellvertretung",
    600: "Zeugwart",

    700: "Sportliche Leitung Stellvertretung",
    800: "Sportliche Leitung",
    900: "Kassier Stellvertretung",
    1000: "Kassier",
    1100: "Schriftführer Stellvertretung",
    1200: "Schriftführer",
    1300: "Obfrau/Obmann Stellvertretung",
    1400: "Obfrau/Obmann",


    //100: "Vorstand"
};

const domainMapInitial: _domainMap = {
    "my.vereinfacht.com": {
        verein_ID: 7,
        fullName: "recordee",
        fullNameDeclined: "recordee",
        shortName: "recordee",
        domain: "my.vereinfacht.com",
        title: "recordee - Better Quality Remote Video Recording",
        showLogo: true,
        logo: <h1 style={{fontSize: "160%"}}>Recordee</h1>,
        iban: "",
        facebook: "",
        color: "#ea4f3d",
        fontColor: "#fff",
        fontColorInverse: "#ea4f3d",
        arnName: "recordee <recordee@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: false,
        memberStates: {},
        address: "Goldschlagstraße 110/30, 1150 Vienna",
        creditorID: "AT26ZZZ000000123456",
    },
};
const domainMap: _domainMap = Object.keys(domainMapInitial).reduce((obj, curr) => ({
    ...obj,
    [curr]: {
        ...domainMapInitial[curr],
        iban: domainMapInitial[curr].iban,
        memberStatesShort: Object.keys(domainMapInitial[curr].memberStates)
            .reduce((obj, k) => ({
                ...obj,
                [parseInt(k)]: domainMapInitial[curr].memberStates[parseInt(k)].split(" ").map((u: any) => u[0]).join(".")
            }), {})
    }
}), {});
const domainName = window.location.hostname.split(".").slice(-2).join(".");
const domainNameLong = window.location.hostname.split(".").slice(-3).join(".");
const clubMap = Object.keys(domainMap).reduce((obj, curr) => ({...obj, [domainMap[curr].verein_ID]: curr}), {});


let ClubPart = domainMap[domainNameLong] || (
    (domainName.indexOf("localhost") > -1 || domainName.indexOf("10.0.0") > -1 || !domainMap[domainName]) ?
        domainMap["vereinfacht.at"] :
        domainMap[domainName]
);

ClubPart = domainMap["my.vereinfacht.com"];

const Club = {
    ...ClubPart,
}; // domain: window.location.host


export {Club, domainMap, clubMap};
