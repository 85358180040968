import React, {Component} from "react";
import Status from "./status";
import {Container} from "./input_fields";
import {apiGet, apiPost} from "./api";
import {UserContext} from "../user/UserContext";
import Consts from "./consts";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: "", catchedError: false};
    }

    static contextType = UserContext;

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error: error.toString()};
    }

    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        if (this.state.catchedError) {
            return
        }
        /*if (Consts.f(false, true)) {
            this.apiPost("/error/log", {
                "message": error.toString(),
                "info": info.componentStack.toString(),
                "pathname": window.location.href,
                "user": this.context.user !== undefined ? this.context.user : null,
                "time": new Date(),
            }, () => {
                console.log("successfully stored error in database")
            }, true);
        }*/
        this.setState({catchedError: true});
        console.log(error, info);

    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Container name={"Something went wrong!"}>
                <br/>
                <Status type={"error"} text={this.state.error}/><br/>
                Please contact <a href="mailto:max.matschiner@linkom.at">max.matschiner@linkom.at</a>!
            </Container>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary
