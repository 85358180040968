import React, {useContext, useMemo, useState} from "react";
import {Container, MaxBtn} from "../core/components";
import {browserVersion, isChrome, isMobileSafari} from "react-device-detect";
import {maxiGet, maxiPost} from "../core/maxios";
import {CheckboxInput, LightContainer, MyModal, TextfieldInput} from "../core/input_fields";
import {Redirect} from "react-router";
import Status from "../core/status";
import {FormContextWrapper} from "../core/form_context";
import "./style.sass"
import {RecordeeContext} from "./context";
import Jitsi from "./Jitsi";
import Socket from "./socket";
import {FaCircle, FaInfoCircle, FaMicrophone, FaUser, FaVideo} from "react-icons/all";
import Login from "../user/login";
import {UserContext} from "../user/UserContext";
import {Counter} from "./helpers";
import ShowRecordings from "./recordings";
import Consts from "../core/consts";

const compatibleBrowser = isChrome && browserVersion >= 53

class NameInput extends React.Component {
    state = {
        name: ""
    };
    setState = this.setState.bind(this);
    handleSubmit = close => e => {
        e && e.preventDefault();
        if (this.state.name.length < 3) {
            this.setState({error: "The name has to be longer than two characters!"})
            return false;
        }
        if (this.state.privacyPolicy !== 1) {
            this.setState({error: "Please first read the privacy policy!"})
            return false;
        }
        close()
        this.props.setUserNameFull(this.state.name)
    }
    ;

    render() {
        return <MyModal defaultOpen trigger={""} onClose={() => this.handleSubmit(() => {
        })(null)}>
            {close =>
                <Container name={"Please tell us your name"}>

                    <Status type={"error"} text={this.state.error}/>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit(close)}>
                        <TextfieldInput name={"Name"} labelWidth={60} tag={"name"} style={{width: "300px"}}/><br/>
                        <br/>
                        <label>
                            <CheckboxInput name={""} noLabel tag={"privacyPolicy"}/> &nbsp; &nbsp; I have read the privacy policy located <a href={"//www.recordee.io/privacypolicy"}>here</a>.

                        </label>
                        <br/>
                        <br/>
                        <MaxBtn>Save</MaxBtn>
                    </FormContextWrapper>
                </Container>
            }
        </MyModal>

    }
}


export default function Recordee({match, router, ...rest}) {
    const [roomName, setRoomName] = useState(match?.params?.roomName || null);
    const user = (useContext(UserContext) || {}).user || {};
    const [userNameState, setUserName] = useState(localStorage.getItem("userName"));
    const userName = user.prename ? user.prename + " " + user.lastname : userNameState;
    const [state, setState] = useState(
        localStorage.getItem("recordingConfig") ? JSON.parse(localStorage.getItem("recordingConfig")) : {recordingType: "videoaudio"}
    );
    const [devices, setDevices] = useState([]);
    const [error, setError] = useState(undefined);
    const [otherParticipants, setOtherParticipants] = useState([]);
    const userLoggedIn = user.status === "logged_in";

    const [files, setFiles] = useState(null);
    const loadFiles = () => {
        if (!userLoggedIn) {
            console.log("recordee return in load files")
            return
        }
        maxiGet({
            url: "/conferences/" + (roomName || "all") + "/recordings",
            success: resp => {
                if (resp.Contents?.length !== files?.Contents?.length) {

                    setFiles(resp)
                }
            }
        });
    }

    const setUserNameFull = userName => {
        setUserName(userName)
        localStorage.setItem("userName", userName)
    }
    const getDeviceList = () => compatibleBrowser && navigator.mediaDevices.getUserMedia({video: true, audio: true}).then(a => {
        a.getTracks().forEach(track => track.stop());

        navigator.mediaDevices.enumerateDevices().then(devRaw => {
            const dev = devRaw.filter(({label}) => !label.startsWith("Default"));
            if (dev.find(({kind}) => kind === "videoinput").length === 0) {
                setError("No video input device found.")
            }
            if (dev.find(({kind}) => kind === "audioinput").length === 0) {
                setError("No audio input device found.")
            }


            setState(lstate => {
                const videoDevice = (lstate.videoDeviceId && dev.find(({deviceId, kind}) => deviceId === lstate.videoDeviceId && kind === "videoinput")) || dev.find(({kind}) => kind === "videoinput")
                const audioDevice = (lstate.audioDeviceId && dev.find(({deviceId, kind}) => deviceId === lstate.audioDeviceId && kind === "audioinput")) || dev.find(({kind}) => kind === "audioinput")
                return {
                    ...lstate,
                    videoDeviceId: videoDevice?.deviceId,
                    videoDeviceIdLabel: videoDevice?.label,
                    audioDeviceId: audioDevice?.deviceId,
                    audioDeviceIdLabel: audioDevice?.label,
                }
            });

            setDevices(dev)
        }).catch(e => {

            console.log("recordee", e)
        })
    })
    useMemo(getDeviceList, []);
    useMemo(() => navigator.mediaDevices.ondevicechange = getDeviceList, [])

    if (match?.params?.roomName !== roomName && roomName) {
        return <Redirect to={"/" + roomName}/>
    }


    return <RecordeeContext.Provider value={{
        userName, userID: user.ID, roomName, state, setState, otherParticipants, setOtherParticipants
    }}>
        <div id={"recordeeNavBar"}>
            <Container name={"recordee"} hideHeading>
                <h2>
                    <a href={"/"}>recordee</a>
                    <div id={"UserLogin"}>


                        <MyModal trigger={<div className={"loginStatus"}><FaUser/> {userName}</div>}>
                            {
                                close => user.ID ?
                                    <Container name={"Log Out"}>

                                        <MaxBtn onClick={(e) => {

                                            if (user.status === "logged_in") {
                                                maxiGet({
                                                    url: "/user/logout", success: _ => {
                                                    }
                                                });
                                                window.setTimeout(() => {
                                                    window.location.href = "/"
                                                }, 1000)
                                            } else {
                                                console.log({user})
                                            }
                                        }
                                        }>
                                            Logout
                                        </MaxBtn>
                                    </Container> :
                                    <Login {...rest}/>
                            }

                        </MyModal>
                        <RecordingStatus/>
                    </div>
                </h2>

                {
                    userName === null && <NameInput {...{setUserNameFull}} {...rest}/>
                }

                {
                    userLoggedIn && <MaxBtn onClick={() => {
                        if (userName === null) {
                            alert("You have to type in a username first.")
                            window.location.reload()
                        }
                        const rn = Math.round(Math.random() * 1000000) + 100000
                        setRoomName(user.ID.toString() + "-" + rn.toString())
                    }}>Start New Room</MaxBtn>
                }
                {
                    compatibleBrowser && state.videoDeviceId &&
                    <Socket room={roomName} userLoggedIn={userLoggedIn} reload={loadFiles} progressBarContainer/>
                }

                {
                    compatibleBrowser && roomName &&
                    <form onSubmit={e => e.preventDefault()} onChange={(e) => {
                        if (state.startTime > 0) {
                            alert("The audio/video devices cannot be changed during recording.")
                        } else {
                            let newState = {}
                            if (e.target.name === "recordingType") {
                                newState = {...state, recordingType: e.target.value}
                            } else {
                                newState = {
                                    ...state,
                                    [e.target.name]: e.target.value,
                                    [e.target.name + "Label"]: devices.filter(({kind}) => kind === e.target.name.slice(0, 5) + "input").filter(({deviceId}) => deviceId === e.target.value)[0].label,
                                }
                            }
                            localStorage.setItem("recordingConfig", JSON.stringify(newState))
                            setState(newState)

                        }
                    }}>
                        {
                            userLoggedIn && parseInt(roomName.split("-")[0]) === user.ID && <label>
                                <b>record</b>
                                &nbsp;
                                <select name={"recordingType"} value={state.recordingType}>
                                    <option value={"videoaudio"}>video + audio</option>
                                    <option value={"audio"}>audio only</option>
                                </select>
                            </label>
                        }
                        {
                            <label>
                                <FaVideo/>
                                &nbsp;
                                <select name={"videoDeviceId"} value={state.videoDeviceId}>
                                    {
                                        devices.filter(({kind}) => kind === "videoinput").map(dev => <option key={dev.deviceId} value={dev.deviceId}>{dev.label}</option>)
                                    }
                                </select>
                            </label>
                        }
                        {
                            <label>
                                <FaMicrophone/>
                                &nbsp;
                                <select name={"audioDeviceId"} value={state.audioDeviceId}>
                                    {
                                        devices.filter(({kind}) => kind === "audioinput").map(dev => <option key={dev.deviceId} value={dev.deviceId}>{dev.label}</option>)
                                    }
                                </select>
                            </label>
                        }
                    </form>
                }
                {
                    userLoggedIn && <br/>
                }
            </Container>
        </div>
        <div id={"behindnav"}></div>
        {
            roomName && compatibleBrowser && userName && state.streamStarted && Consts.f(false, true) &&
            <Jitsi isPowerUser={true} roomName={roomName} reload={() => {
            }}/>
        }
        <Status type={"error"} text={error}/>
        <Container name={""} hideHeading>
            <div id={"progressBarContainer"}/>

            {
                !roomName && compatibleBrowser && userLoggedIn && "Please click on Start New Room first to get into a new room!"
            }
            {
                !roomName && !userLoggedIn && <Login {...rest}/>
            }
            {
                !isChrome && " For the recording and video conference to work one has to use Google Chrome!"
            }
            {
                browserVersion < 53 && " For the recording and video conference to work one has to use Google Chrome newer than version 53! You currently use " + browserVersion + "!"
            }
            {
                userLoggedIn && roomName && <ShowOtherParticipants parts={otherParticipants}/>
            }

            {
                userLoggedIn && <ShowRecordings {...{roomName, userLoggedIn, setRoomName, loadFiles, files}}/>
            }
            <h4>You have questions or do not know what to do? Read the <a href={"/faq"} target={"_blank"} rel={"noopener noreferrer"}>FAQ</a>!</h4>

            <hr/>
            © Linkomat GmbH, {new Date().getFullYear()}, Goldschlagstraße 110/30, 1150 Vienna, Austria, <a href={"//www.recordee.io/privacypolicy"} target={"_blank"}>Privacy Policy</a>
        </Container>
    </RecordeeContext.Provider>
}


function

ShowOtherParticipants({parts}) {
    if ((parts || []).length === 0) {
        return null
    }

    //console.log("recordee", parts)
    return <LightContainer name={"Participants"}>{
        (parts || [])
            .map(({name, specs, progressList, webcamName, micName, token}) =>
                <MyModal key={token} trigger={<>{localStorage.getItem("token") === token ? "myself" : name} <FaInfoCircle/>
                    {progressList && progressList.length > 0 && localStorage.getItem("token") !== token && progressList.map(({progress}) => `(${progress} % uploading)`)}
                    &nbsp;&nbsp;&nbsp;</>}>
                    <LightContainer name={name}>
                        <table>
                            {Object.entries({
                                Resolution: specs?.resolution?.width && `${specs?.resolution?.width}x${specs?.resolution?.height}`,
                                "Frame rate": specs?.frameRate && `${specs?.frameRate}fps`,
                                Webcam: typeof webcamName === "string" && webcamName,
                                Mic: typeof micName === "string" && micName,
                                Connection: specs?.connection?.effectiveType && ({"slow-2g": "very bad", "2g": "bad", "3g": "ok", "4g": "good"}[specs?.connection?.effectiveType] || specs?.connection?.effectiveType),
                                Downlink: specs?.connection?.downlink && (specs?.connection?.downlink + "mbit/s"),
                                "Round trip time / delay (average)": specs?.connection?.rtt && (specs?.connection?.rtt + "ms"),
                                "Time left on battery": !specs?.battery ? "not on battery" : Math.round(specs?.battery / 60) + " minutes",
                                Progress: progressList && progressList.length > 0 && localStorage.getItem("token") !== token && progressList.map(({progress, progressTotal}) => `${progress} % of ${progressTotal} mb`).join(", "),
                            })
                                .filter(([key, val]) => val)
                                .map(([key, val]) => <tr>
                                    <td>{key}</td>
                                    <td>{typeof val === "string" && val}</td>
                                </tr>)}
                        </table>
                    </LightContainer>
                    <ParticipantSettings token={token} resolution={specs?.resolution}/>

                </MyModal>)
    }
    </LightContainer>
}

const
    Bitrates = [
        400, 800, 1200, 1600, 2400, 3200, 4800, 7200,
    ]


function

ParticipantSettings({resolution, token}) {
    const context = useContext(RecordeeContext);
    const [state, setState] = useState({});
    const loadSettings = () => maxiGet({
        url: "/recordee/" + context?.roomName + "/settings/" + token,
        success: setState,
    })
    useMemo(loadSettings, [])
    const changeSettings = e => {

        try {
            setState({...state, [e.target.name]: e.target.value,})
            maxiPost({
                url: "/recordee/" + context?.roomName + "/settings",
                data: {
                    token, attribute: e.target.name, value: e.target.value,
                }
            })
        } catch (err) {
            console.log(err)
        }

    }
    //.filter(a => a.width <= resolution?.width)
    return <LightContainer name={"Settings for this user"}>
        <label>
            Maximal Bitrate: <select name="maxBitrate" value={state.maxBitrate} onChange={changeSettings}>
            <option value={"unlimited"}>unlimited</option>
            {Bitrates.map(kbit => <option value={kbit}>up to {(kbit / 1000).toFixed(1)} MBit/s (produces files up to {(kbit / 8 * 3.6).toFixed(0)} MB/hour)</option>)}
        </select>
        </label>
    </LightContainer>
}

function

RecordingStatus() {
    const startTime = ((useContext(RecordeeContext) || {}).state || {}).startTime;

    return startTime ?
        <div className={"recorderStatus"}>
            Recording <Counter start={startTime}/> <FaCircle/>
        </div> : null


}


Number
    .prototype
    .pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}

