import React from "react";
import {Container} from "../core/components";
import {BsFillGridFill, MdChatBubble} from "react-icons/all";

export function FAQ({}) {
    return <>
        <Container name={"Your Contact Person:"}>
            <b>Max Matschiner</b>
            <br/>
            <a href={"mailto:max@recordee.io"}>max@recordee.io</a> &nbsp;<br/>
            <a href={"tel:00436645644055"}>+43 664 5644055</a><br/>
        </Container>
        <Container name={"FAQ"}>
            <Test>
                <QandA question={"How do I use recordee on my PC or Mac?"}>
                    You just need to register for a free account on <a href="https://www.recordee.io/signup" target="_BLANK" rel="noopener noreferrer">https://www.recordee.io/signup</a> to host a meeting or get an invitation to join a meeting as a guest. You need to have the latest version of Google
                    Chrome
                    installed for recordee to work best.
                </QandA>
                <QandA question={"Do you need an account to use recordee?"}>
                    A recordee account is not required if you are strictly joining recordee meetings as a participant. If someone invites you to their meeting, you can join as a participant without creating an account.<br/>
                    A recordee account is only required if you need to create your own meetings and send invitations to participants. Having a recordee account allows you to create your own Instant.
                    An account also allows you to access your personal settings, where you can update your profile or upgrade your plan at any time.
                </QandA>
                <QandA question={"How do I sign-up for recordee?"}>
                    You can sign up for a free recordee account at <a href="https://www.recordee.io/signup" target="_BLANK" rel="noopener noreferrer">https://www.recordee.io/signup</a>.
                </QandA>
                <QandA question={"How much does recordee cost?"}>
                    A basic recordee license is free. You can write to us if you want to learn more about available recordee plans and pricing.
                </QandA>
                <QandA question={"How do I join a recordee meeting?"}>
                    You can join a meeting by clicking the meeting link.
                </QandA>
                <QandA question={"How do I join computer/device audio?"}>
                    On most devices, you can join computer/device audio by clicking Join Audio, Join with Computer Audio, or Audio to access the audio settings.
                </QandA>
                <QandA question={"Can I Use Bluetooth Headset?"}>
                    Yes, as long as the Bluetooth device is compatible with the computer or mobile device that you are using.
                </QandA>
                <QandA question={"Do I have to have a webcam to join on recordee?"}>
                    While you are not required to have a webcam to join a recordee meeting, you will not be able to transmit video of yourself. You will continue to be able to listen and speak during the meeting, share your screen, and view the webcam video of other participants.
                </QandA>
                <QandA question={"How do I invite others to join my meeting?"}>

                    You can invite others to join your meeting by clicking on start new room and sharing the URL via email or any social media tool.
                </QandA>
                <QandA question={"Can I record my meeting?"}>
                    All recordee hosts can record in HD the video and audio stream of every single participant to the meeting.
                    Hosts who are Licensed can also take advantage of the AI enabled frame enhancement solution which improves the quality of the video by adding the missing frames when the webcam is not recording at its full capacity, due to technical or light related issues.
                    In a recordee meeting, press “start recording” to start the recording. Please note that once you click on stop recording, the videos from the participants will upload in the cloud and the browser cannot be closed until the process is finished, or the recording will be lost.
                </QandA>
                <QandA question={"Where do I find my recording?"}>
                    By default, local recordings are saved under the “recordings” section, just under the list of participants. The host will be able to save a file for each participants in different formats and with a AI frame enhanced version, if Licenced.
                </QandA>

                <QandA question={"How do I reset my password?"}>
                    You can reset your password at <a href={"https://my.recordee.io"}>my.recordee.io</a> password.
                </QandA>
                <QandA question={"My video/camera isn't working."}>
                    Make sure Google Chrome is allowed to access your camera in your pc settings.
                </QandA>
                <QandA question={"There is echo in my meeting."}>

                    Echo can be caused by many things, such as a participant connected to the meeting audio on multiple devices or two participants joined in from the same local. Wearing a headset will solve the issue in most cases. If you are still having issues, please submit a request.
                </QandA>
                <QandA question={"How do I share my screen?"}>

                    Click the Share Screen button located in your meeting controls.
                </QandA>
                <QandA question={"How do I change the view of video of the participants to the meeting?"}>

                    By clicking on the Tile View Icon (<BsFillGridFill/>).
                </QandA>
                <QandA question={"How do I chat with the participants?"}>
                    By clicking on speaking / chat bubble on the lower left of the meeting window (<MdChatBubble/>).
                </QandA>
                <QandA question={"How to I share a recorded video?"}>
                    By right clicking and copying the address of the video in your list of recordings.
                </QandA>
                <QandA question={"How do I use multiple webcams to record from different angles?"}>
                    Just connect as many devices you want to the meeting by opening the meeting link in different tabs and select the webcam and microphone from the drop-down menus at the top of the meeting window.
                </QandA>
                <QandA question={"What to do if your background noise is disturbing the interview?"}>
                    Download <a href="https://www.krisp.ai?ref=maxmatschiner" target="_BLANK" rel="noopener noreferrer">KRISP.ai </a> which removes your background noise with the help of AI.
                </QandA>
            </Test>
        </Container>
    </>
}

function Test({children}) {
    return children.map(({props, type: TAG}, i) => <TAG question={<>{i+1}. {props.question}</>}>{props.children}</TAG>)
}

function QandA({question, children}) {
    return <div>
        <h3>{question}</h3>
        <p>{children}</p>
    </div>
}
