import axios from "axios";
import Consts from "./consts";

type AnyVoidFunc = (arg: any) => {};

interface Maxios {
    url: string,
    data?: any,
    noApi?: boolean,
    noErrorSet?: boolean,
    success: AnyVoidFunc,
    setError?: AnyVoidFunc,
}


function maxiGet({url, success, noApi, noErrorSet, setError}: Maxios): void {

    axios.get((!noApi ? Consts.API_PREFIX : "") + url, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {

            if (response.data.status === "error") {
                throw Error(response.data.message)
            } else {
                success && success(response.data)
            }
        })
        .catch((error) => {
            console.log("error", error);
            if (!noErrorSet && setError !== undefined) {
                setError(error)
            }
        });
}

function maxiPost({url, data, success, noApi, noErrorSet, setError}: Maxios): void {
    axios.post((!noApi ? Consts.API_PREFIX : "") + url, data, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then(response => {

            if (response.data.status === "error") {
                throw Error(response.data.message)
            } else {
                success && success(response.data)
            }
        })
        .catch((error) => {
            console.log("error", error);
            if (!noErrorSet && setError !== undefined) {
                setError(error)
            }
        });
}


export {maxiGet, maxiPost}
