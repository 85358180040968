var AWS = require("aws-sdk");
const bucket = "recordee"

export function getFileSystem(callback) {
    window.navigator.webkitPersistentStorage && window.navigator.webkitPersistentStorage.requestQuota(3 * 1024 * 1024 * 1024, grantedBytes => {
        window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
        window.requestFileSystem(
            window.PERSISTENT,
            grantedBytes,
            callback,
        )
    });
}

export function getAndUpload(fileObject, progressEvent, setError) {
    getFileSystem(fs => {
            fs.root.getFile(fileObject.fileName, {create: false}, fileEntry => {
                fileEntry.file(async file => createMultipartUpload(fileObject.fileName, fileObject.room, fileObject.credentials, file, progressEvent, fileObject.multipart, setError))
            })
        }
    )

}

export function createMultipartUpload(fileName, dir, credentialsPass, videoBlob, progressEvent, multipart = null, setError = null) {
    var s3 = new AWS.S3({
        credentials: new AWS.Credentials(credentialsPass.AccessKeyId, credentialsPass.SecretAccessKey, credentialsPass.SessionToken),
        params: {Bucket: bucket},
        endpoint: "https://s3.linkom.at",
        signatureVersion: "v2",
    });
    var multiPartParams = {
        Bucket: bucket,
        Key: "recordings/" + dir + fileName,
    };
    if (!multipart) {
        s3.createMultipartUpload(multiPartParams, async (mpErr, multipart) => {
            if (mpErr) {
                console.log('Error!', mpErr);
                setError && setError("Error while initiating the multipart upload " + String(mpErr))
                return;
            }
            console.log("Got upload ID", multipart.UploadId);
            const exist = JSON.parse(localStorage.getItem("uploadStore")) || {}
            localStorage.setItem("uploadStore", JSON.stringify({...exist, [fileName]: {...(exist[fileName] || {}), multipart},}))
            await uploadMultipart(multipart, s3, fileName, videoBlob, progressEvent, setError)
        });
    } else {
        window.setTimeout(() => uploadMultipart(multipart, s3, fileName, videoBlob, progressEvent, setError), 0);
    }

}

const partSize = 1024 * 1024 * 5;

export async function uploadMultipart(multipart, s3, fileName, videoBlob, progressEvent, setError) {
    let buffer = videoBlob.slice();
    const existParts = JSON.parse(localStorage.getItem(multipart.Key) || "[]")
    let partNum = existParts.length - 1

    for (let rangeStart = (partNum + 1) * partSize; rangeStart < buffer.size; rangeStart += partSize) {
        partNum++;
        var end = Math.min(rangeStart + partSize, buffer.size),
            partParams = {
                Body: buffer.slice(rangeStart, end),
                Bucket: bucket,
                Key: multipart.Key,
                PartNumber: String(partNum + 1),
                UploadId: multipart.UploadId
            };

        // Send a single part
        console.log('Uploading part: #', partParams.PartNumber, ', Range start:', rangeStart);
        await uploadPart(s3, multipart, partParams, 3, progressEvent(buffer.size, rangeStart), setError);
    }
    //console.log("parts", JSON.parse(localStorage.getItem(multipart.Key)))
    s3.completeMultipartUpload({
        Bucket: bucket,
        Key: multipart.Key,
        MultipartUpload: {Parts: JSON.parse(localStorage.getItem(multipart.Key))},
        UploadId: multipart.UploadId
    }, (err, data) => {
        if (err) {
            console.log("An error occurred while completing the multipart upload");
            setError && setError("Error while completing the multipart upload " + String(err))
            console.log(err);
        } else {
            //clean up
            localStorage.removeItem(multipart.Key)
            const exist = JSON.parse(localStorage.getItem("uploadStore")) || {}
            localStorage.setItem("uploadStore", JSON.stringify(Object
                .entries(exist)
                .reduce((obj, [key, curr]) => key !== fileName ?
                    ({...obj, [key]: curr}) :
                    obj,
                    {}
                ))
            )
            progressEvent(buffer.size, null)(null)
            getFileSystem((fs) => {
                    fs.root.getFile(fileName, {create: false}, fileEntry => fileEntry.remove(_ => 1))
                }
            )

        }
    })

}


async function uploadPart(s3, multipart, partParams, tryNum, progressEvent, setError) {
    tryNum = tryNum || 1;

    try {

        const resp = await s3.uploadPart(partParams).on("httpUploadProgress", progressEvent).promise()

        const obj = {
            ETag: resp.ETag,
            PartNumber: Number(partParams.PartNumber)
        }
        console.log("uploaded obj", obj)
        localStorage.setItem(multipart.Key, JSON.stringify([...JSON.parse(localStorage.getItem(multipart.Key) || "[]"), obj]))
        return obj
    } catch (e) {
        console.log('multiErr, upload part error:', e);
        if (tryNum < 3) {
            console.log('Retrying upload of part: #', partParams.PartNumber)
            setError && setError("Error while uploading a part. Please check your internet connection. The system automatically tries to upload it again. " + String(e))
            await uploadPart(s3, multipart, partParams, tryNum + 1);
        } else {
            console.log('Failed uploading part: #', partParams.PartNumber)
            setError && setError("Retry error while uploading a part. Please check your internet connection and reload the page. " + String(e))
        }
        return;
    }
}
