class ConstsClass {
    fieldTranslation = {
        prename: "Vorname",
        lastname: "Nachname",
        phone: "Telefon",
        email: "E-Mail",
        pricenm: "Preis (kein Mitglied)",
        price: "Preis",
        olderthan: "Älter als",
        youngerthan: "Jünger als",
        street: "Straße",
        city: "Stadt",
        postal_code: "PLZ",
        postalCode: "PLZ",
        route: "Straße",
        locality: "Ort",
        birthdate: "Geburtsdatum",
        deathdate: "Sterbedatum",
        member: "Mitglied",
        memberStatus: "Mitglieds-Status",
        memberStatusNew: "Mitglieds-Status",
        memberStatusDescription: "Positionsbezeichnung",
        bank: "Banküberweisung", card: "Kreditkartenzahlung", sofort: "Sofortüberweisung", brig: "Bankbuchung", sepa_debit: "SEPA Lastschrift Einzug", voucher: "Gutschein",
        titlesAnte: "Titel vorangestellt",
        titlesPost: "Titel nachgestellt",
        titlesJob: "Berufsbezeichnung",
        svn: "Sozialvers.-Nr.",
        letterAddressation: "Briefanrede",
        companyName: "Firmenname",
        companyPosition: "Position in Firma",
        vatID: "UID-Nummer",
        companyRegNr: "FB-Nummer",
        phoneAdd: "Telefon 2",
        source: "Erfassungs-Quelle",
        sex: "Geschlecht",
        IDLegacy: "vorherige ID",
        zvrNr: "ZVR-Nr",
        name: "Name",
        gender: "Geschlecht",
        minparts: "Minimale Teilnehmer",
        maxparts: "Maximale Teilnehmer",
        priceInput: "Preis (€)",
        pricenmInput: "Preis kein Mitglied (€)",
        bookingStart: "Buchbar ab",
        bookingEnd: "Buchbar bis",
        showStart: "Anzeigen ab",
        showEnd: "Anzeigen bis",
        yearAllowedLeft: "Jahrgänge nach (JJJJ)",
        yearAllowedRight: "Jahrgänge vor (JJJJ)",
        membersOnly: "Mitgliedschaft erforderlich?",
        audience: "Zielgruppe",
        rolesAllowed: "Definierende Rollen",
        tags: "Tags",
        description: "Beschreibung",
        information: "Information",
    };
    placeHolders = {
        svn: "1234010101",
        email: "max@mustermann.at",
        prename: "Max",
        lastname: "Mustermann",
        phone: "+43 664 1234567",
        companyName: "Muster GmbH",
        vatID: "ATU12345678",
        companyRegNr: "123456a",
        name: "Allgemeiner Musterverein",
        website: "www.musterverein.at",
        zvrNr: 12456789,
    };
    weekdayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];

    /*memberStates = {
        0: "Nichtmitglied",
        1: "ordentliches Mitglied",
        2: "außerordentliches Mitglied",
        3: "unterstützendes Mitglied",

        4: "befreites Mitglied",
        5: "Zeugwart Stellvertretung",
        6: "Zeugwart",

        7: "Sportliche Leitung Stellvertretung",
        8: "Sportliche Leitung",
        9: "Kassier Stellvertretung",
        10: "Kassier",
        11: "Schriftführer Stellvertretung",
        12: "Schriftführer",
        13: "Obfrau/Obmann Stellvertretung",
        14: "Obfrau/Obmann",

        40: "ruhendes Mitglied",
        41: "Trainer",

        //100: "Vorstand"
    };
    memberStatesShort = Object.keys(this.memberStates).reduce((obj, k) => ({...obj, [k]: this.memberStates[k].split(" ").map(u => u[0]).join(".")}), {});*/
    kontenKinds = {0: "Allgemeines Konto", 1: "Bank", 5: "Kundenkonto", 2: "Kontenklasse", 3: "Kostenträger"};
    buchSymbole = {AR: "Ausgangsrechnung", ER: "Eingangsrechnung", BB: "Bilanzbuchung", BU: "Buchungsanweisung", EB: "Eröffnungsbilanz", KA: "Kassa", SO: "Sonstige Belege", BK: "Bank", LG: "Lohn/Gehalt", UE: "Saldenübernahme"};
    sexMap = {male: "männlich", fem: "weiblich"};
    translate = (name) => this.fieldTranslation[name] || (name[0] || "").toUpperCase() + name.substr(1);


    money = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    moneyAlt = new Intl.NumberFormat('de-AT', {
        style: 'currency',
        currency: 'ATS',
        minimumFractionDigits: 2
    });

    constructor() {
        this.PHASE = process.env.NODE_ENV;
        this.f = this.f.bind(this);
        let f = this.f;

        this.API_PREFIX = f("", "/api");
    }

    f(dev, prod, user_id = -1) {
        if (process.env.NODE_ENV === "development" || (user_id !== -1 && this.user_id_prod.indexOf(user_id) < 0)) {
            return dev
        }
        return prod
    }

    moneyMax = (s, currency = 0) => {
        return currency === 0 ? this.money.format(s) : this.moneyAlt.format(s)
    };

    telCommonCountryCodes = [
        43,
        39,
        32,
        371,
        387,
        382,
        385,
        212,
        420,
        31,
        45,
        47,
        372,
        48,
        358,
        351,
        33,
        7,
        49,
        421,
        350,
        386,
        44,
        34,
        30,
        46,
        36,
        41,
        353,
        90,
    ];
    ResolutionsToCheck = [
        {width: 320, height: 180},
        {width: 640, height: 360},
        {width: 640, height: 480},
        {width: 768, height: 576},
        {width: 1024, height: 576},
        {width: 1280, height: 720},
        {width: 1920, height: 1080},
        //{width: 2560, height: 1440},
        {width: 3840, height: 2160},
    ].reverse();

    isNumber= function(value) {
        return typeof value === 'number' &&
            isFinite(value) &&
            Math.floor(value) === value;
    };
    phoneNormalise(s) {
        if (this.isNumber(s) || !s)
            return s;

        s = s.replace(" ", "");
        if (s === "")
            return 0;
        s = s.replace(".", "").replace("-", "").replace("/", "");

        if (s.length < 5)
            return parseInt(s);

        if (s[0] === "+")
            s = s.substr(1);

        s = s.replace(/^0+/, "");

        if (!(this.telCommonCountryCodes.indexOf(parseInt(s.substr(0, 2))) > -1 || this.telCommonCountryCodes.indexOf(parseInt(s.substr(0, 3))) > -1)) {
            s = "43 " + s;
        } else {
            s = s.substr(0, 2) + " " + s.substr(2)
        }
        const cell = parseInt(s.substr(3, 3));
        let dist = 7;
        if (650 <= cell && cell <= 699) {
            dist = 6;
        }
        return "+" + s.substr(0, dist) + " " + s.substr(dist)
    }
}

const Consts = new ConstsClass();

export default Consts;
