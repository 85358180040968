import React from "react"

function dateFormatUnix(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    //return date_string
    //return d.toLocaleDateString("en-US")
    return new Intl.DateTimeFormat('de-AT', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',

    }).format(d);
}

function dateFormatUnixSmall(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    //return date_string
    return new Intl.DateTimeFormat('de-AT', {
        year: '2-digit',
        month: 'short',
        day: '2-digit',

    }).format(d).split(".").join("").replace("i","");
}

function dateFormatTime(inp) {
    if (inp) {
        return new Intl.DateTimeFormat('de-AT', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: "2-digit",
            weekday: "short"
        }).format(inp < 1000000000000 ? inp * 1000 : inp);
    }
    return "unbekannt"
}

function dateFormatTimeShort(inp) {
    if (inp !== 0) {
        return new Intl.DateTimeFormat('de-AT', {
            year: '2-digit',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: "2-digit",
        }).format(inp * 1000);
    }
    return "unbekannt"
}

function timeFormat(inp) {
    if (inp !== 0) {
        return new Intl.DateTimeFormat('de-AT', {
            hour: 'numeric',
            minute: "2-digit",
        }).format(inp * 1000);
    }
    return "unbekannt"
}

function dateFormat(date) {
    if (date === 0) {
        return ""
    }
    date = `${date}`;
    let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
    let d = new Date(date_string);
    //return date_string
    if (isNaN(d.getTime())) {
        console.log(date)
        return ""
    }
    return new Intl.DateTimeFormat('de-AT', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',

    }).format(d);
}

const monthNames = [
    "Jan.", "Feb.", "Mär.", "Apr.", "Mai", "Jun.", "Jul.", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."
]

function dateFormatUnixInline(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    return dateFormatDateInline(d)
}

const dateFormatDateInline = d => <span className={"dateFormat"}>
        <span>{String("0" + d.getDate()).slice(-2)}.</span>&nbsp;
    <span>{monthNames[d.getMonth()]}</span>&nbsp;
    {d.getFullYear()}
    </span>;

function dateFormatInline(date) {
    if (date === 0) {
        return ""
    }
    date = `${date}`;
    let date_string = `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
    let d = new Date(date_string);
    //return date_string
    return dateFormatDateInline(d)
}

//741

export {dateFormatUnix, dateFormat, dateFormatInline, dateFormatTime, timeFormat, dateFormatUnixSmall, dateFormatUnixInline, dateFormatTimeShort, dateFormatDateInline}