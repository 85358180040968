import React, {createRef, useMemo, useState} from "react"
//import { Media, Player, controls } from 'react-media-player'
//const { PlayPause, MuteUnmute } = controls
export default function VideoEditor() {
    const video = createRef();
    const video2 = createRef();
    const [paused, setPaused] = useState(true);
    const [intervall, setIntervall] = useState(null);

    useMemo(() => {
        console.log("recordee", video.current)
        if (video.current) {
            video.current.ontimeupdate = t => {
                console.log("recordee", t)
            }
        }
    }, [!!video.current])
    const test = (v) => {
        const interv = window.setInterval(() => {
            const f=v.currentTime * 30
            //console.log("recordee", (f))
            if (f >98.99 && f  < 100.5){
                v.style.marginLeft=0
            }
        }, 8) // has minimum 4ms
        setIntervall(interv)
    }
    return <>
        {/*<Media>
            <div className="media">
                <div className="media-player">
                    <Player width={400} src="/test.mp4" onTimeUpdate={t=>{
                        console.log("recordee",Math.round(t.currentTime*30))
                    }}/>
                </div>
                <div className="media-controls">
                    <PlayPause />
                    <MuteUnmute />
                </div>
            </div>
        </Media>*/}
        <section style={{width: 380, overflow: "hidden",border: "2px solid red", marginLeft: 300}}>
            <video width={800} style={{marginLeft: -400}} ref={video} height={"auto"} src={"https://s3.linkom.at/recordee/recordings/1-123/1592780718.0119731-Max%20Matschiner.webm"}/>
        </section>
        <br/>
        <button onClick={() => {
            if (paused) {
                video.current.play()
                test(video.current)
            } else {
                video.current.pause()
                clearInterval(intervall)
            }
            /*video.current.ontimeupdate = t => {
                console.log("recordee", t.timeStamp, t.target.currentTime*30)
            }*/
            setPaused(!paused)
        }
        }>
            play/stop
        </button>
<br/>
        <video width={400} ref={video2} src={"https://s3.linkom.at/recordee/recordings/1-123/1593092007.8114011-Stefano%20DAmico.webm"} mediaGroup={"aljsdlf"} controls>
        </video>
        <video width={400}  mediaGroup={"aljsdlf"}  src={"https://s3.linkom.at/recordee/recordings/1-123/1593092008.0989993-Max%20Matschiner.webm"}>
            <track src={"https://s3.linkom.at/recordee/recordings/1-123/1593092007.431694-room.webm.mp4"}/>
        </video>
        <br/>
        <button onClick={()=>{
            video2.current.controller.play()
        }
        }>
            play
        </button>
        <button onClick={()=>{
            console.log("recordee",video2.current.audioTracks)
            //video2.current.track[Math.round(Math.random()*3)].mode="SHOWING"
        }
        }>
            change
        </button>
    </>
}
